<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-describedby="modal-title"
    (click)="closeModal()"
  >
    <span class="sr-only">Zamknij Okno</span>
  </button>
</div>
<div class="modal-body">
  <div #messagesContainer class="messages-wrapper overflow-auto">
    <ul class="list-unstyled m-0">
      @for (message of messages; track message) {
        <li
          class="message-row rounded p-1 mx-1 mb-2"
          [ngClass]="getMessageBackgroundColorClass(message.author)"
          #messagesQueryList
        >
          <div class="d-flex message-details">
            <div>
              <p>
                {{ message.addPerson }}
                {{ message.addDate | date: 'dd-MM-yyyy HH:mm:ss' }}
              </p>
            </div>
            <div>
              @if (controlConfigs.recipientLabelVisible) {
                <div>
                  {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.RECIPIENT' | translate }}:
                  {{ message.recipientsName }}
                </div>
              }
              @if (message.readerString) {
                <div title="{{ message.readerString }}">
                  {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.READ_IT_2' | translate }}:
                  <span class="fa fa-info-circle"></span>
                </div>
              }
            </div>
          </div>
          <div>
            <p class="m-0">{{ message.messageContent }}</p>
          </div>
        </li>
      }
    </ul>
  </div>
  @if (controlConfigs.extendedColourCodedMessagesByAuthor) {
    <div class="mt-2">
      <h4 class="mb-1 fs-6">
        {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.LEGEND' | translate }}
      </h4>
      <ul class="list-unstyled d-flex flex-row gap-2 w-100 mb-0">
        <li>
          <div class="form-control form-control-sm message-color-red">
            {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.ODGIK' | translate }}
          </div>
        </li>
        <li>
          <div class="form-control form-control-sm message-color-blue">
            {{ 'GK.DYNAMIC_LIST.COMMUNICATOR.DESIGNER_PORTAL' | translate }}
          </div>
        </li>
        <li>
          <div class="form-control form-control-sm message-color-green">
            {{
              'GK.DYNAMIC_LIST.COMMUNICATOR.COORDINATION_MEEETING_PORTAL'
                | translate
            }}
          </div>
        </li>
      </ul>
    </div>
  }
</div>
<div class="modal-footer flex-column flex-nowrap">
  <label class="sr-only" for="new-message">Wpisz nową wiadomość</label>
  <textarea
    class="form-control form-control-sm new-message-input required m-0"
    placeholder="{{
      (isPendingMeeting
        ? 'GK.DYNAMIC_LIST.COMMUNICATOR.PLACEHOLDER_NEW_MESSAGE'
        : 'GK.DYNAMIC_LIST.COMMUNICATOR.PLACEHOLDER_CANNOT_SEND'
      ) | translate
    }}"
    name="new-message"
    id="new-message"
    rows="2"
    (keydown.enter)="handleSendMessage($event)"
    [(ngModel)]="newMessage"
    [disabled]="!isPendingMeeting"
  ></textarea>
  <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100 gap-2">
    @if (controlConfigs.recipientSelectVisible) {
      <label for="recipientSelect" class="w-35"
        >{{
          'GK.DYNAMIC_LIST.COMMUNICATOR.MESSAGE_RECIPIENT' | translate
        }}:</label
      >
      <select
        [(ngModel)]="selectedRecipientId"
        class="mb-1 mb-sm-0 form-select"
        id="recipientSelect"
        name="recipientSelect"
      >
        <option [ngValue]="undefined" selected></option>
        @for (recipient of recipients; track recipient) {
          <option [ngValue]="recipient.id">
            {{ recipient.name }}
          </option>
        }
      </select>
    }
    <button
      type="button"
      class="btn btn-success"
      (click)="sendMessage()"
      [disabled]="!newMessage"
    >
      {{
        messagesPending ? '' : ('GK.DYNAMIC_LIST.COMMUNICATOR.SEND' | translate)
      }}
      <i [ngClass]="messagesPending ? 'fa fa-spinner fa-spin fa-sm' : ''"></i>
    </button>
  </div>
</div>
